<script>
import Navbar from "@/components/navbar";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import { ArrowUpIcon } from "vue-feather-icons";
import ApiService from "@/services/api.service";
import moment from "moment";
import { mapGetters } from "vuex";
import BillplzDetails from "./billplz-details";

export default {
  data() {
    return {
      transactions: [],
      selectedTransaction: {},
      date: null,
      endDate: null,
      checkDate: null,
      status: 0,
      excel_file: null,
      searchData: "",
      pagination: {
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
      },
      total: {
        pending: 0,
        verified: 0,
        sum: 0,
        sumPending: 0,
        sumVerified: 0,
        count_exist: 0,
        count_not_exist: 0,
      },
      responseDate: {
        start: null,
        end: null,
      },
    };
  },
  components: {
    Navbar,
    ArrowUpIcon,
    Footer,
    BillplzDetails,
  },
  mounted() {
    if (this.$route.query.date) {
      this.date = this.$route.query.date;
      this.status = this.$route.query.status;
    } else {
      this.date = moment().format("YYYY-MM-DD");
      this.endDate = moment().add(1, "day").format("YYYY-MM-DD");
    }

    this.getTransactionsList();
  },
  watch: {
    date: {
      handler: function (val) {
        // this.endDate = moment(val).add(1, "day").format("YYYY-MM-DD");
      },
    },
  },
  methods: {
    getTransactionsList() {
      ApiService.get(
        `ez-qurban/transaction?start_date=${this.date}&end_date=${this.endDate}&status=${this.status}&report_verification=true&limit=${this.pagination.perPage}&page=${this.pagination.currentPage}&search=${this.searchData}`
      ).then((response) => {
        this.transactions = response.data.transactions.data;
        this.pagination.totalRows = response.data.transactions.total;
        this.total.pending = response.data.total_pending;
        this.total.verified = response.data.total_verified;
        this.total.sum = response.data.sum_pending + response.data.sum_verified;
        this.total.sumVerified = response.data.sum_verified;
        this.total.sumPending = response.data.sum_pending;
        this.responseDate.start = response.data.dates.start;
        this.responseDate.end = response.data.dates.end;
      });
    },
    pageClicks(page) {
      this.pagination.currentPage = page;
      this.getTransactionsList();
    },
    changePerPage() {
      this.pagination.currentPage = 1;
      this.getTransactionsList();
    },
    async setVerified(transaction) {
      this.$swal.fire({
        icon: "warning",
        html: "Adakah anda benar-benar yakin untuk meluluskan transaksi ini?",
        showCancelButton: true,
        focusConfirm: true,
        confirmButtonText: "Luluskan",
        cancelButtonText: "Batal",
        confirmButtonColor: "#3085d6",
        showLoaderOnConfirm: true,
        preConfirm: async (login) => {
          return await ApiService.post(
            `ez-qurban/transaction/${transaction.id}`,
            {
              _method: "PATCH",
              status: 1,
            }
          ).then((response) => {
            transaction.verified_at = moment();
            this.total.pending -= 1;
            this.total.verified += 1;
          });
        },
      });
    },
    search(status) {
      this.pagination.currentPage = 1;
      if (status != null) {
        this.status = status;
      }
      this.getTransactionsList();
    },
    getExcel() {
      window.open(
        process.env.VUE_APP_API_URL +
          `/ezqurban/transaction/report/daily?start_date=${this.date}&end_date=${this.endDate}&no_paginate=true`,
        "_blank"
      );
    },
    showUploadModal() {
      this.$refs["upload-modal"].show();
    },
    hideUploadModal() {
      this.$refs["upload-modal"].hide();
    },
    onFileChange() {
      this.excel_file = this.$refs.excel_file.files[0];
    },
    async onSubmitExcel() {
      let formData = new FormData();
      formData.append("excel_file", this.excel_file);

      return ApiService.post(`ezqurban/transaction/report/check`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((response) => {
          this.count_exist = response.data.count_exist;
          this.count_not_exist = response.data.count_not_exist;
          this.$swal.fire({
            icon: "success",
            html: `Rekod yang dimuatnaik sedang diproses. Kami akan menghubungi anda melalui email untuk keputusan semakan`,
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Tutup",
          });
          this.hideUploadModal();
          this.getTransactionsList();
        })
        .catch(function (error) {
          this.$swal.fire({
            icon: "error",
            html: "Maklumat tidak berjaya dikemaskini.",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Okay",
          });
        });
    },
    clickEdit(item) {
      this.selectedTransaction = item;
      this.$refs["edit-modal"].show();
    },
    hideEditModal() {
      this.$refs["edit-modal"].hide();
    },
    async onSubmit() {
      return ApiService.post(
        `ez-qurban/transaction/${this.selectedTransaction.id}`,
        {
          _method: "PUT",
          ref_no: this.selectedTransaction.ref_no,
          client_name: this.selectedTransaction.client_name,
          client_mykad_number: this.selectedTransaction.client_mykad_number,
          client_email: this.selectedTransaction.client_email,
          client_mobile_number: this.selectedTransaction.client_mobile_number,
          product: this.selectedTransaction.product,
          bill_id: this.selectedTransaction.bill_id,
          price: this.selectedTransaction.price,
        }
      )
        .then((response) => {
          this.$refs["edit-modal"].hide();
          this.getTransactionsList();
        })
        .catch(function (error) {
          this.$refs["edit-modal"].hide();
          this.$swal.fire({
            icon: "error",
            html: "Maklumat tidak berjaya dikemaskini.",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "Okay",
          });
        });
    },
  },
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    toHumanDate: function (value) {
      return moment(value).format("D MMMM YYYY");
    },
    toHumanDateTime: function (value) {
      if (value) {
        return moment(value).format("D MMM YY, HH:mm:ss");
      }
      return "";
    },
  },
  computed: {
    ...mapGetters(["isAuthenticated", "currentUser", "userAccess"]),
    orgCode() {
      return this.userAccess?.organization?.code;
    },
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <section class="bg-half bg-primary d-table w-100">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level">
              <h4 class="title text-white" style="text-transform: capitalize">
                {{ $t("ezq-tx") }}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="section">
      <div
        class="
          container
          card
          public-profile
          border-0
          rounded
          shadow
          overflow-hidden
        "
      >
        <div class="card-body">
          <div class="row mt-5">
            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >{{ $t("start-date") }}</label
                >
                <div class="col-sm-8 col-md-8">
                  <input
                    type="date"
                    class="form-control"
                    name=""
                    id=""
                    v-model="date"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >{{ $t("last-date") }}</label
                >
                <div class="col-sm-8 col-md-8">
                  <input
                    type="date"
                    class="form-control"
                    name=""
                    id=""
                    v-model="endDate"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >Status</label
                >
                <div class="col-sm-8 col-md-8">
                  <select name="" id="" v-model="status" class="form-control">
                    <option value="0">{{ $t("not-verified") }}</option>
                    <option value="-1">{{ $t("all") }}</option>
                    <option value="1">{{ $t("verified") }}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group row">
                <label for="staticEmail" class="col-sm-4 col-form-label"
                  >{{ $t("search") }}</label
                >
                <div class="col-sm-8 col-md-8">
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    v-model="searchData"
                  />
                </div>
              </div>
            </div>

            <div class="col-md-12 text-right">
              <button @click="showUploadModal" class="btn btn-primary">
                {{ $t("check-verification") }}
              </button>
              &nbsp;
              <button @click="getExcel" class="btn btn-primary">
                {{ $t("get-excel") }}
              </button>
              &nbsp;
              <button @click="search(null)" class="btn btn-primary">
                {{ $t("search2") }}
              </button>
            </div>
          </div>
          <hr />

          <h5 class="text-center">{{ $t("ezq-tx-on") }}</h5> 
          <h6 class="text-center">
            {{ responseDate.start | toHumanDateTime }} -
            {{ responseDate.end | toHumanDateTime }}
          </h6>
          <div class="row">
            <div class="offset-md-1 col-md-3">
              <a
                href="#"
                @click="search(1)"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>{{ total.verified }}</h3>
                <small>RM {{ total.sumVerified }}</small>
                <h6 class="title text-dark h6 my-0">{{ $t("verified") }}</h6></a
              >
            </div>
            <div class="col-md-3">
              <a
                href="#"
                @click="search(0)"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>{{ total.pending }}</h3>
                <small>RM {{ total.sumPending }}</small>
                <h6 class="title text-dark h6 my-0">{{ $t("not-verified") }}</h6>
              </a>
            </div>
            <div class="col-md-4">
              <a
                href="#"
                class="accounts rounded d-block shadow text-center py-3"
              >
                <h3>{{ total.pending + total.verified }}</h3>
                <small>RM {{ total.sum.toFixed(2) }}</small>
                <h6 class="title text-dark h6 my-0">{{ $t("total-tx") }}</h6></a
              >
            </div>
          </div>

          <div class="table-responsive bg-white shadow rounded mt-3">
            <table
              class="table mb-0 table-center table-bordered"
              style="font-size: 12px"
            >
              <thead class="bg-light">
                <tr>
                  <th rowspan="2">{{ $t("index") }}</th>
                  <th rowspan="2">{{ $t("ref-no") }}</th>
                  <th rowspan="2" class="text-center">{{ $t("purchase") }}</th>
                  <th colspan="4" class="text-center">{{ $t("action") }}</th>
                  <th rowspan="2">{{ $t("system-received-datetime") }}</th>
                  <th rowspan="2">{{ $t("action") }}</th>
                  <th rowspan="2">{{ $t("verification2") }}</th>
                </tr>
                <tr>
                  <th>{{ $t("preference.product") }}</th>
                  <th>{{ $t("org.nav3") }} (RM)</th>
                  <th>{{ $t("channel") }}</th>
                  <th>{{ $t("datetime") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="transactions && transactions.length == 0">
                  <td class="text-center" colspan="14">
                    <h6 class="mt-5 mb-5">{{ $t("no-data") }}</h6>
                  </td>
                </tr>
                <tr v-for="(transaction, index) in transactions" :key="index">
                  <td>
                    {{
                      (pagination.currentPage - 1) * pagination.perPage +
                      index +
                      1
                    }}
                  </td>
                  <td>{{ transaction.ref_no }}</td>
                  <td>
                    {{ transaction.client_name }} <br />
                    <span style="font-size: 10px"
                      >{{ transaction.client_mykad_number }} <br />
                      {{ transaction.client_email }} <br />
                      {{ transaction.client_mobile_number }} <br />
                    </span>
                  </td>
                  <td>{{ transaction.product }}</td>
                  <td class="text-right">{{ transaction.price }}</td>
                  <td>
                    {{ transaction.payment_channel }} <br />
                    <div
                      v-if="
                        transaction.bill_id != 'id' && transaction.bill_id != ''
                      "
                    >
                      {{ transaction.bill_id }}
                    </div>
                  </td>
                  <td>
                    {{ transaction.product_purchased_at | toHumanDateTime }}
                  </td>
                  <td>
                    {{ transaction.created_at | toHumanDateTime }}
                  </td>
                  <td class="text-center">
                    <span
                      v-if="transaction.verified_at"
                      class="badge badge-success"
                      >{{ $t("yes") }}</span
                    >
                    <span
                      v-if="!transaction.verified_at"
                      class="badge badge-warning"
                      >{{ $t("not-yet") }}</span
                    >
                  </td>
                  <td class="text-center">
                    <div v-if="orgCode == 'EZQ'">
                      <b-button
                        id="show-btn"
                        size="sm"
                        variant="secondary"
                        @click="clickEdit(transaction)"
                      >
                        {{ $t("update") }}
                      </b-button>
                    </div>
                    <div v-else>
                      <div>
                        <b-dropdown
                          id="dropdown-1"
                          v-bind:text="$t('select-action')"
                          size="sm"
                          class="m-md-2"
                          v-if="!transaction.verified_at"
                        >
                          <b-dropdown-item @click="setVerified(transaction)">
                            {{ $t("passed") }}
                          </b-dropdown-item>
                          <b-dropdown-divider></b-dropdown-divider>
                          <b-dropdown-item
                            id="show-btn"
                            @click="clickEdit(transaction)"
                          >
                            {{ $t("update") }}
                          </b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- Upload Verifikasi Modal -->
          <b-modal
            ref="upload-modal"
            id="upload-modal"
            hide-footer
            centered
            title="Semak Verifikasi"
          >
            <form id="edit-form" @submit.prevent="onSubmitExcel">
              <div class="form-group">
                <label for="InputName">{{ $t("upload-doc") }} (.xls, .csv)</label>
                <input
                  type="file"
                  name="excel_file"
                  ref="excel_file"
                  class="form-control form-control-file"
                  id="InputFile"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  v-on:change="onFileChange"
                />
              </div>

              <b-button class="mt-5" variant="primary" type="submit" block
                >{{ $t("check") }}</b-button
              >
              <b-button
                class="mt-3"
                variant="outline-secondary"
                block
                @click="hideUploadModal"
                >{{ $t("cancel") }}</b-button
              >
            </form>
          </b-modal>

          <!-- Kemaskini Modal -->
          <b-modal
            ref="edit-modal"
            id="edit-modal"
            hide-footer
            title="Kemaskini Maklumat"
          >
            <form id="edit-form" @submit.prevent="onSubmit">
              <div class="form-group">
                <label for="InputName">{{ $t("ref-no") }}</label>
                <input
                  type="text"
                  name="ref_no"
                  class="form-control"
                  id="InputRefNo"
                  style="text-transform: uppercase"
                  v-model="selectedTransaction.ref_no"
                />
              </div>
              <div class="form-group">
                <label for="InputName">{{ $t("name") }}</label>
                <input
                  type="text"
                  name="client_name"
                  class="form-control"
                  id="InputName"
                  style="text-transform: uppercase"
                  v-model="selectedTransaction.client_name"
                />
              </div>
              <div class="form-group">
                <label for="InputMykad">{{ $t("mykad") }}</label>
                <input
                  type="text"
                  name="client_mykad_number"
                  class="form-control"
                  id="InputMykad"
                  v-model="selectedTransaction.client_mykad_number"
                />
              </div>
              <div class="form-group">
                <label for="InputEmail">{{ $t("email") }}</label>
                <input
                  type="text"
                  name="client_email"
                  class="form-control"
                  id="InputEmail"
                  v-model="selectedTransaction.client_email"
                />
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="InputPhone">{{ $t("mobile-no") }}</label>
                    <input
                      type="text"
                      name="client_mobile_number"
                      class="form-control"
                      id="InputPhone"
                      v-model="selectedTransaction.client_mobile_number"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="InputName">{{ $t("preference.product") }}</label>
                    <input
                      type="text"
                      name="product"
                      class="form-control"
                      id="InputProduct"
                      v-model="selectedTransaction.product"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6">
                  <div
                    v-if="
                      selectedTransaction.bill_id != 'id' &&
                      selectedTransaction.bill_id != ''
                    "
                    class="form-group"
                  >
                    <label for="InputBillID">{{ $t("bill-id") }}</label>
                    <input
                      type="text"
                      name="bill_id"
                      class="form-control"
                      id="InputBillID"
                      v-model="selectedTransaction.bill_id"
                    />
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group">
                    <label for="InputPrice">{{ $t("org.nav3") }} (RM)</label>
                    <input
                      type="number"
                      name="price"
                      class="form-control"
                      id="InputPrice"
                      v-model="selectedTransaction.price"
                    />
                  </div>
                </div>
              </div>

              <b-button class="mt-3" variant="primary" type="submit" block
                >{{ $t("save") }} </b-button
              >
              <b-button
                class="mt-3"
                variant="outline-secondary"
                block
                @click="hideEditModal"
                >{{ $t("cancel") }}</b-button
              >
            </form> </b-modal
          ><!--Kemaskini Modal end-->

          <!-- Pagination -->
          <div class="row">
            <div class="col-lg-9 col-md-7">
              <b-pagination
                v-model="pagination.currentPage"
                :total-rows="pagination.totalRows"
                :per-page="pagination.perPage"
                @change="pageClicks"
                aria-controls="my-table"
              ></b-pagination>
            </div>
            <div class="col-lg-3 col-md-5 mt-4 mt-sm-0 pt-2 pt-sm-0">
              <div class="form custom-form">
                <div class="form-group mb-0">
                  <select
                    v-model="pagination.perPage"
                    class="form-control custom-select"
                    id="Sortbylist-job"
                    @change="changePerPage()"
                  >
                    <option value="5">5 {{ $t("per-page") }}</option>
                    <option value="10">10 {{ $t("per-page") }}</option>
                    <option value="25">25 {{ $t("per-page") }}</option>
                    <option value="50">50 {{ $t("per-page") }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center mt-4">
        <router-link
          :to="{
            name: 'ezqurban.fund-transfer.list',
            query: {
              date: this.date,
            },
          }"
          class="btn btn-primary"
          >{{ $t("transfer-funds") }}</router-link
        >
      </div>
    </section>
    <BillplzDetails ref="modalBillplz" />
    <Footer />
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
  </div>
</template>
