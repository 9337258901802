var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', {
    ref: "modal",
    attrs: {
      "no-enforce-focus": true,
      "ok-only": "",
      "id": "modal-prevent-closing",
      "title": ("Perincian Transaksi Billplz: " + (_vm.transaction.bill_id))
    }
  }, [_vm.isBillFound === false ? _c('div', {
    staticClass: "p-5"
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v("Bill tidak dijumpai")])]) : _vm._e(), _vm.billplz ? _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_vm.billplz.paid ? _c('div', {
    staticClass: "alert alert-success"
  }, [_vm._v(" Bayaran Diterima: RM " + _vm._s(_vm.billplz.paid_amount.amount / 100) + " ")]) : _vm._e(), _vm.amountNotMatch ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_c('strong', [_vm._v("Bayaran Tidak Sama")]), _c('ul', [_c('li', [_vm._v("Billplz: RM " + _vm._s(_vm.billplz.paid_amount.amount / 100))]), _c('li', [_vm._v("EzQurban: RM " + _vm._s(_vm.transaction.price))])])]) : _vm._e(), !_vm.billplz.paid ? _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v(" Bayaran Belum Diterima ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-12"
  }, [_c('h4', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.transaction.ref_no))])])]), _c('div', {
    staticClass: "row mt-3"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Collection ID")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.collection_id))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Bill Description")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.description))])])])]), _c('div', {
    staticClass: "row"
  }, [_vm.billplz.reference_1 ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Ref. 1: " + _vm._s(_vm.billplz.reference_1_label))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.reference_1))])])]) : _vm._e(), _vm.billplz.reference_2 ? _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Ref. 2: " + _vm._s(_vm.billplz.reference_2_label))]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.reference_2))])])]) : _vm._e()]), _c('hr'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("Nama")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.name))])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("E-mel")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.email))])])]), _c('div', {
    staticClass: "col-md-4"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    staticClass: "m-0",
    attrs: {
      "for": ""
    }
  }, [_vm._v("No. Telefon")]), _c('p', {
    staticClass: "form-control-static"
  }, [_vm._v(_vm._s(_vm.billplz.mobile))])])])])]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }